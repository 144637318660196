<!--
 * @Description: 新增学员
 * @Author: xiawenlong
 * @Date: 2021-04-22 14:34:47
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-09 16:52:08
-->
<template>
  <div class="student-add">
    <el-table ref="multipleTable" stripe :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="name" label="员工姓名">
        <template slot-scope="scope">
          <el-input :ref="'staffName' + scope.$index" v-model="scope.row.studentName"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="员工手机号">
        <template slot-scope="scope">
          <el-input
            :ref="'staffPhone' + scope.$index"
            v-model="scope.row.mobile"
            maxlength="11"
          ></el-input> </template
      ></el-table-column>
      <el-table-column prop="address" label="身份证/护照">
        <template slot-scope="scope">
          <el-input :ref="'staffCardId' + scope.$index" v-model="scope.row.cardNo"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="部门(选填)">
        <template slot-scope="scope">
          <!-- <el-select v-model="scope.row.depts" multiple placeholder="请选择">
            <el-option
              v-for="item in deptSelect"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select> -->

          <el-cascader
            v-model="scope.row.deptPath"
            :options="deptSelect"
            :props="deptProps"
            :show-all-levels="false"
            clearable
            @change="handleDeptChange(scope.row)"
          >
          </el-cascader>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <i
            v-if="scope.$index == tableData.length - 1"
            class="el-icon-circle-plus-outline"
            @click="handleAdd(scope.$index)"
          ></i>
          <i
            v-if="scope.$index != tableData.length - 1"
            class="el-icon-remove-outline"
            @click="handleRemove(scope.$index)"
          ></i>
          <i class="el-icon-refresh" @click="handleClear(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" :loading="loading" @click="create">提交</el-button>
  </div>
</template>
<script>
const PhoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
const IDCardRegex = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
const PassportRegex = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
import { deptTree } from '@/api/dept'
import { studentCreate } from '@/api/student'
import to from 'await-to'
export default {
  name: 'StudentAdd',
  data() {
    return {
      loading: false,
      tableData: [{}, {}, {}, {}, {}],
      deptSelect: [],
      deptProps: {
        expandTrigger: 'hover',
        multiple: false,
        value: 'deptId',
        label: 'name',
      },
    }
  },
  mounted() {
    this.deptTree()
  },
  methods: {
    async deptTree() {
      const [res, err] = await to(deptTree({}))
      if (err) return this.$message.warning(err.msg)
      this.deptSelect = res.data
    },
    handleAdd() {
      this.tableData.push({})
    },
    handleRemove(index) {
      this.tableData.splice(index, 1)
    },
    handleClear(index) {
      this.tableData.splice(index, 1, {})
    },
    handleDeptChange(row) {
      let deptPath = row.deptPath
      if (!deptPath || deptPath.length == 0) {
        return (row.depts.length = 0)
      }
      row.depts = [deptPath[deptPath.length - 1]]
    },
    async create() {
      this.tableData = this.tableData.map((item, index) => ({ ...item, index }))
      let students = this.tableData.filter(
        value => value.studentName || value.mobile || value.cardNo,
      )
      if (this.loading || !students.length) {
        this.$message.warning('请输入员工信息')
        return
      }
      let msg = []

      students.forEach(stu => {
        if (!stu.studentName) {
          this.$refs['staffName' + stu.index].focus()
          msg.push(`请填写第${stu.index + 1}行员工姓名`)
        }
        if (!stu.mobile) {
          this.$refs['staffPhone' + stu.index].focus()
          msg.push(`请填写第${stu.index + 1}行员工的手机号`)
        }
        if (stu.mobile && !PhoneRegex.test(stu.mobile)) {
          this.$refs['staffPhone' + stu.index].focus()
          msg.push(`第${stu.index + 1}行员工手机号格式不正确,请重新输入`)
        }
        if (stu.cardNo && !IDCardRegex.test(stu.cardNo) && !PassportRegex.test(stu.cardNo)) {
          this.$refs['staffCardId' + stu.index].focus()
          msg.push(`第${stu.index + 1}行员工身份证/护照格式不正确`)
        }
      })
      if (msg.length) return this.$message.warning(msg.join('，'))
      this.loading = true
      const [, err] = await to(studentCreate({ students }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push('/student/list')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-add {
  ::v-deep.el-table {
    tr {
      td {
        &:first-of-type {
          > div {
            width: 40px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            color: #333333;
            margin: 0 10px;
          }
        }
      }
    }
  }
  ::v-deep.el-table__fixed-right {
    i {
      cursor: pointer;
      color: #606266;
      font-size: 30px;
      margin-right: 10px;
      &.el-icon-circle-plus-outline {
        color: #ff7b33;
      }
      &.el-icon-remove-outline {
        color: #f8341e;
      }
    }
  }
  .el-button {
    display: block;
    margin: 50px auto;
  }
}
</style>
